import React from "react";
import { fetchGenreDetails } from "../../utils/data_utils";
import { useParams } from 'react-router-dom';
import { qwebApi } from '../../qt/qwebApi';
import BackButton from "../back_button/back_button";
import GameTile from "../game_tile/game_tile";
import { Constants } from "../../constants";
import { ShimmerLoaderEffects } from "../shimmer_effect/shimmer_effect";
import { SomethingWentWrong } from "../something_went_wrong/something_went_wrong";
import { useTranslation } from 'react-i18next';

function CategoryDetailHeaderBar({ data }) {
    const { t } = useTranslation();
    const gameString = data.gameList.length > 1 ? t('games', { prefix: data.gameList.length }) : "1 " + t('game');
    return <div className="category-details-toolbar">
        <div className="category-details-toolbar__leading">
            <BackButton hideText={true} />
            <div className="category-details-toolbar__title">{"" + data.genreType + " " + t('games', { prefix: "" })}</div>
        </div>
        <div className="category-details-toolbar__ending">
            <div className="category-details-toolbar__games-count">{gameString}</div>
        </div>
    </div>
}

export default function CategoryDetailPage() {
    const params = useParams()
    const genre = params['genre']
    let [inAsyncCall, setInAsyncCall] = React.useState(true);
    let [error, setError] = React.useState(null);
    let [genreData, setGenreData] = React.useState({});
    let fetchSetGenreData = (genre) => {
        fetchGenreDetails(genre)
            .then(setGenreData)
            .then((_) => { setInAsyncCall(false) })
            .catch(setError)
    }
    React.useEffect(() => {
        setTimeout(() => {
            qwebApi({
                event: 'changeBannerBackgroundImage',
                data: {
                    url: Constants.landingPageBackgroundUrl,
                },
            });
        }, 100);
    }, []);
    React.useEffect(() => {
        fetchSetGenreData(genre);
    }, []);

    React.useEffect(() => {
        const onVisibilityChange = () => {
            if (!document.hidden) {
                setTimeout(() => {
                    qwebApi({
                        event: 'changeBannerBackgroundImage',
                        data: {
                            url: Constants.landingPageBackgroundUrl,
                        },
                    });
                }, 100);
                window.location.reload();
            }
        }
        document.addEventListener('visibilitychange', onVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        }
    }, [])


    if (inAsyncCall) {
        return <div className="category-details">
            {ShimmerLoaderEffects.GenreDetailPage()}
        </div>
    }
    else if (error) {
        return <SomethingWentWrong error={error} onRefreshClick={() => { fetchSetGenreData(genre) }} />
    }
    else if (genreData != {}) {
        return <div className="category-details">
            <CategoryDetailHeaderBar data={genreData} />
            <div className="category-details-game-grid">
                {genreData.gameList.map((game, index) => {
                    return <GameTile key={index}
                        game={game}
                        genreSectionType={genreData.genreType.toLowerCase().replace(' ', '-')}
                    />
                })}
            </div>
        </div>
    }
}