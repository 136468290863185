import React from 'react';
import nextStep from '../../assets/ic_next_step.png';
import { useTranslation } from 'react-i18next';

function Step({ stepDetails, i }) {
	const { t } = useTranslation();
	return (
		<div className="getting-started__single-step">
			<img
				className="getting-started__single-step__image"
				src={stepDetails.icon}
				alt=""
			></img>
			<div className="getting-started__single-step__info">
				<div className="getting-started__single-step__number">{t('step', { step: i + 1 })}</div>
				<div className="getting-started__single-step__title">
					{stepDetails.title}
				</div>
			</div>
		</div>
	);
}

function getStepsWithArrow(data) {
	let arr = [];
	for (let i = 0; i < data.gettingStarted.length; i++) {
		arr.push(<Step stepDetails={data.gettingStarted[i]} i={i} key={i} />);
		if (i < data.gettingStarted.length - 1) {
			arr.push(
				<img
					key={i + data.gettingStarted.length}
					className="getting-started__steps__delimiter"
					src={nextStep}
					alt=""
				></img>
			);
		}
	}
	return arr;
}

export default function GettingStarted({ data }) {
	const { t } = useTranslation();
	if (data.gettingStarted.length === 0) {
		return
	}
	return (
		<div className="section">
			<div className="section__top">
				<div className="section__top__title">{t('gettingStarted')}</div>
			</div>
			<div className="getting-started__steps">{getStepsWithArrow(data)}</div>
		</div>
	);
}
