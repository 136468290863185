import React from "react";

class ShimmerLoaderEffects {
    static LandingPage = () => {
        return <div className="landing-shimmer-loader__wrapper">
            <div className="landing-shimmer-loader__featured">
                <div className="shimmer-loader landing-shimmer-loader__banner border-radius-12">
                    <div className="shimmer-loader landing-shimmer-loader__banner__button view-detail-button border-radius-8"></div>
                </div>
                <div className="landing-shimmer-loader__game-tile-list">
                    {[1, 2, 3, 4].map((_, i) => {
                        return <div className="shimmer-loader landing-shimmer-loader__game-tile border-radius-12" key={i}>
                            <div className="shimmer-loader game-icon border-radius-12"></div>
                            <div className="shimmer-loader game-title border-radius-4"></div>
                        </div>
                    })}
                </div>
            </div>
            <div className="landing-shimmer-loader__list-section">
                <div className="shimmer-loader heading border-radius-4"></div>
                <div className="landing-shimmer-loader__game-list-grid">
                    {[1, 2, 3, 4].map((_, i) => {
                        return <div className="shimmer-loader game-tile border-radius-8" key={i}></div>
                    })}
                </div>
            </div>
            <div className="landing-shimmer-loader__list-section">
                <div className="shimmer-loader heading border-radius-4"></div>
                <div className="landing-shimmer-loader__game-list-grid">
                    {[1, 2, 3, 4].map((_, i) => {
                        return <div className="shimmer-loader game-tile border-radius-8" key={i}></div>
                    })}
                </div>
            </div>
        </div>
    }
    static GameDetailPage = () => {
        return <div className="detail-page-shimmer">
            <div className="shimmer-loader heading border-radius-4"></div>
            <div className="detail-page-shimmer__game-info">
                <div className="left-side">
                    <div className="shimmer-loader game-logo border-radius-8"></div>
                    <div className="shimmer-loader border-radius-8 game-info"></div>
                    <div className="shimmer-loader border-radius-8 game-description"></div>
                </div>
                <div className="right-side">
                    <div className="shimmer-loader border-radius-8 monetary-stats"></div>
                    <div className="shimmer-loader border-radius-8 play-now"></div>
                </div>
            </div>
            <div className="landing-shimmer-loader__list-section">
                <div className="shimmer-loader heading border-radius-4"></div>
                <div className="landing-shimmer-loader__game-list-grid">
                    {[1, 2, 3, 4].map((_, i) => {
                        return <div className="shimmer-loader game-tile border-radius-8" key={i}></div>
                    })}
                </div>
            </div>
        </div>
    }
    static GenreDetailPage = () => {
        return <div className="genre-shimmer-loader__wrapper">
            <div className="genre-shimmer-loader__toolbar">
                <div className="shimmer-loader heading border-radius-4"></div>
                <div className="shimmer-loader heading border-radius-4"></div>
            </div>
            <div className="genre-gamelist-grid">
                {[1, 2, 3, 4, 5, 6].map((_, i) => {
                    return <div className="shimmer-loader game-tile border-radius-8" key={i}></div>
                })}
            </div>
        </div>
    }
}

export { ShimmerLoaderEffects }