import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sendClickEvent } from '../../constants';
import { useTranslation } from 'react-i18next';

export default function GenreSection({ genreData }) {
    const genreKeys = Object.keys(genreData);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const gradients = [
        ['#DC5408', '#8E01B1'],
        ['#58DC08', '#0172B1'],
        ['#D8DC08', '#B12101'],
        ['#D808DC', '#1A01B1'],
        ['#08B6DC', '#0128B1'],
        ['#08DC83', '#015DB1'],
        ['#DC5408', '#8E01B1'],
        ['#58DC08', '#0172B1'],
        ['#D8DC08', '#B12101'],
    ]

    const Genre = ({ genreName, genreEN, index }) => {
        const getOnTap = () => {
            navigate('/genre/' + genreEN, { state: { genre: genreEN } });
            sendClickEvent({
                'banner_location': 'click_web3_webapp_genre_' + genreEN.toLowerCase().replace(' ', '-'),
                'event_type': 'App_JumpToDetailPage'
            });
        }
        const style = {
            backgroundImage: `linear-gradient(108.74deg,  ${gradients[index][0]} , ${gradients[index][1]})`,
        }
        return <div className='genre' onClick={getOnTap} style={style}>{genreName}</div>
    }

    return <div className='section' key={'genre-section'}>
        <div className="section__top">
            <div className="section__top__title">{t('browseByGenre')}</div>
        </div>
        <div className="section__game-list">
            {genreKeys.map((genre, i) => {
                return <Genre genreName={genreData[genre]} genreEN={genre} index={i} key={i} />
            })}
        </div>
    </div>
}