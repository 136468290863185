import React from 'react';

function FilledStar() {
    return <div className='filled-star'>★</div>
}

function HollowStar() {
    return <div className='hollow-star'>★</div>
}

function getNumeralRating(value, hideValue) {
    if (!hideValue) {
        return <div className='star-rating__text'>{value}/5</div>
    }
}

export default function Rating({ value, hideNumericValue, maxValue }) {
    let filledStars = Math.round(value)
    let totalStars = maxValue ? maxValue : 5;
    return <div className='star-rating'>
        {[...Array(filledStars)].map((_, i) => {
            return <FilledStar key={i} />
        })}
        {[...Array(totalStars - filledStars)].map((_, i) => {
            return <HollowStar key={i} />
        })}
        {getNumeralRating(value, hideNumericValue)}
    </div>
}