import React from 'react';
import CircularTokenImage from '../circular_token_image/circular_token_image.js';

export default function SingleTokenTagPill({ token }) {
    return <div className='tag-pill-token'>
        <div className='tag-pill-token-type'>{token.type}</div>
        <div className='tag-pill'>
            <CircularTokenImage token={token} />
            <div className='tag-pill__title'>{token.name}</div>
        </div>
    </div>
}