import './description_widget.scss';

const DescriptionWidget = ({ iconUrl, title, subtitle }) => {
    return <div className="description-widget">
        <img className="icon" src={iconUrl} />
        <div className="details">
            <div className="title">{title}</div>
            <div className="sub-title">{subtitle}</div>
        </div>
    </div>
}

export default DescriptionWidget;