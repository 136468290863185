import React, { useEffect } from 'react';
import DetailPageHeader from '../detail_page_header/detail_page_header';
import BackButton from '../back_button/back_button';
import GameMedia from '../game_media/game_media';
import TopNFTs from '../top_nfts/top_nfts';
import GettingStarted from '../getting_started/getting_started';
import { useParams } from 'react-router-dom';
import { qwebApi } from '../../qt/qwebApi';
import { fetchGameDetail, } from '../../utils/data_utils';
import { SomethingWentWrong } from '../something_went_wrong/something_went_wrong';
import { ShimmerLoaderEffects } from '../shimmer_effect/shimmer_effect';
import FeaturedIcon from '../../assets/ic_featured_game.png';

export default function GameDetailPage() {
	const params = useParams()
	const gameId = params['gameId']

	let [inAsyncCall, setInAsyncCall] = React.useState(true);
	let [error, setError] = React.useState(null);
	let [gameDetailData, setGameDetailData] = React.useState({});
	let { isFeatured } = gameDetailData || {};
	var [bannerImg, setBannerImg] = React.useState('');

	const changeBackgroundImage = (imgSrc) => {
		qwebApi({
			event: 'changeBannerBackgroundImage',
			data: {
				url: imgSrc,
			},
		});
	}

	let fetchSetGameDetailData = (gameId) => {
		fetchGameDetail(gameId)
			.then((gameDetailDataObject) => {
				setGameDetailData(gameDetailDataObject)
				changeBackgroundImage(gameDetailDataObject.banner);
				setBannerImg(gameDetailDataObject.banner);
			})
			.then((_) => { setInAsyncCall(false) })
			.catch(setError)
	}
	useEffect(() => {
		fetchSetGameDetailData(gameId);
	}, []);

	useEffect(() => {
		var onVisibilityChange = () => {
			if (!document.hidden) {
				setTimeout(() => {
					qwebApi({
						event: 'changeBannerBackgroundImage',
						data: {
							url: bannerImg,
						},
					});
				}, 100);
				window.location.reload();
			}
		}
		document.addEventListener('visibilitychange', onVisibilityChange)

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange)
		}
	}, [bannerImg])

	// Scroll
	useEffect(() => {
		document.addEventListener('scroll', onScroll);
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant',
		});
		function onScroll(event) {
			qwebApi({
				event: 'onPageScroll',
				data: {
					x: 0,
					y: document.documentElement.scrollTop,
				},
			});
		}
		return () => {
			document.removeEventListener('scroll', onScroll);
		};

	}, []);

	if (error) {
		return <SomethingWentWrong error={error} onRefreshClick={() => { fetchSetGameDetailData(gameId) }} />
	}
	else if (inAsyncCall) {
		return ShimmerLoaderEffects.GameDetailPage();
	}

	else if (gameDetailData != {}) {
		return (
			<div className="detail-page" id="detail-page">
				<div className='flex space-between'>
					<BackButton />
					{isFeatured ? <img className='featured-icon' src={FeaturedIcon} /> : null}
				</div>
				<DetailPageHeader data={gameDetailData} />
				<GameMedia data={gameDetailData} />
				<TopNFTs data={gameDetailData} />
				<GettingStarted data={gameDetailData} />
				{/* <UserReviews /> */}
			</div>
		);
	}
}
