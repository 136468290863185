import React from 'react';
import ScrollButtons from '../scroll_buttons/scroll_buttons';
import { useTranslation } from 'react-i18next';

function GameImage({ image }) {
	return <img className="game-media__image" src={image} ></img >;
}

export default function GameMedia({ data }) {
	const { t } = useTranslation();
	if (data.gameMedia.length === 0) {
		return
	}
	return (
		<div className="section">
			<div className="section__top">
				<div className="section__top__title">{t('gameMedia')}</div>
				<ScrollButtons scrollableContainerId={"scrollable-game-media"} />
			</div>
			<div className="section__game-list" id="scrollable-game-media">
				{data.gameMedia.map((imgSrc, i) => {
					return <GameImage image={imgSrc} key={i} />;
				})}
			</div>
		</div>
	);
}
