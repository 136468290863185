import './read_more_popup.scss';

const ReadMorePopup = ({ gameDetailData }) => {
  const { gameName = "", icon = "", description = "", categories = [] } = gameDetailData || {};

  return <div className='read-more-popup'>
    <div className='header'>
      <img src={icon} alt="" />
      <div className='game-props'>
        <div className='game-name'>{gameName}</div>
        <div className='game-tags'>
          {
            categories.map((category, index) => {
              return <div key={index} className='tag'>{category}</div>
            })
          }
        </div>
      </div>
    </div>
    <div className='description' dangerouslySetInnerHTML={{ __html: description }} />
  </div>
}

export default ReadMorePopup