import React from 'react';
import button from './../../assets/ic_back.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ButtonText({ hideText }) {
	const { t } = useTranslation();
	if (!hideText) {
		return <div className="back-button__text">{t('goBack')}</div>
	}
	return null;
}

export default function BackButton({ hideText }) {
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<div className="back-button" onClick={() => {
			if (location.key !== 'default') {
				navigate(-1)
			} else {
				navigate('/')
			}
		}}>
			<img className="back-button__ic" alt="" src={button}></img>
			<ButtonText hideText={hideText} />
		</div>
	);
}
