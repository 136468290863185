import { useTranslation } from "react-i18next"
import Web3GameIcon from '../../../assets/ic_web3_game.png'
import './get_started_with_web3.scss'

const GettingStartedWithWeb3 = () => {
    const { t } = useTranslation();
    return <div className="getting-started-with-web3">
        <div className="web3-card-contents">
            <div className="web3-card-overlay" />
            <div className="web3-card-header">
                <img className="web3-card-icon" src={Web3GameIcon} />
                <div className="web3-card-title">{t('w3getStartedTitle')}</div>
            </div>
            <div className="web3-card-summary">
                {t('w3getStartedSummary')}
            </div>
        </div>

    </div>
}
export default GettingStartedWithWeb3