import React from 'react';
import GameTile from '../game_tile/game_tile';
import ScrollButtons from '../scroll_buttons/scroll_buttons';
import './list_section.css';

function ListSection({ props }) {
	let title = props.title;
	let gameList = props.gameList;
	let source = props.title.replaceAll(' ', '-').toLowerCase()
	return (
		<div className="section" key={title}>
			<div className="section__top">
				<div className="section__top__title">{title}</div>
				<ScrollButtons scrollableContainerId={source} />
			</div>
			<div id={source} className="section__game-list">
				{gameList.map((game, index) => {
					return <GameTile game={game} key={index} source={source} />;
				})}

			</div>
		</div>
	);
}

export default ListSection;
