import React from 'react';
import './index.css';
import LandingPage from './components/landing_page/landing_page';
import GameDetailPage from './components/game_detail_page/game_detail_page';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CategoryDetailPage from './components/category_detail_page/category_detail_page';
import { I18nextProvider } from "react-i18next";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TranslationResources } from './translations/resources';
import { qwebApi, on, off } from './qt/qwebApi';
import { CommonComponentsProvider } from './providers/common_components_provider';
import { fetchClientConfig } from './utils/data_utils';
const router = createBrowserRouter([
    {
        path: '/',
        element: <LandingPage />,
    },
    {
        path: 'details/:gameId',
        element: <GameDetailPage />
    },
    {
        path: 'genre/:genre',
        element: <CategoryDetailPage />
    },
]);


function App() {
    return (
        <CommonComponentsProvider>
            <AppWrapper />
        </CommonComponentsProvider>
    )
}

export default App
function AppWrapper() {

    let [language, setLanguage] = React.useState(null);
    let [config, setConfig] = React.useState(null);

    const onUpdateLanguage = React.useCallback(
        async ({ _, defaultLanguage }) => {
            setLanguage(defaultLanguage.toLowerCase());
        },
        []
    );

    React.useEffect(() => {
        const isQwebCLient = async () => {
            let config = await fetchClientConfig()
            if (config && config.launcher_guid) {
                // fetch language from qweb 
                qwebApi({ event: 'getLanguage', callbackEvent: 'updateLanguage' })
            }
            else {
                // fetch browser language
                setLanguage(navigator.language || navigator.userLanguage);
            }
        }
        isQwebCLient();
    }, [])

    React.useEffect(() => {
        const onVisibilityChange = () => {
            if (!document.hidden) {
                qwebApi({ event: 'getLanguage', callbackEvent: 'updateLanguage' })
            }
        }
        document.addEventListener('visibilitychange', onVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        }
    }, [])

    React.useEffect(() => {
        on('updateLanguage', onUpdateLanguage)
        return () => {
            off('updateLanguage', onUpdateLanguage)
        }
    }, [onUpdateLanguage])

    if (language) {
        return <RouterProvider router={router} >
            <I18nextProvider i18n={
                i18n
                    .use(initReactI18next)
                    .init({
                        debug: true,
                        lng: language,
                        resources: TranslationResources
                    })
            }>
            </I18nextProvider>
        </RouterProvider>
    }
}