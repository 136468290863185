import React from 'react';
import { Constants } from '../../constants';
import { useTranslation } from 'react-i18next';

function NftTile({ nft }) {
	const getSlicedNftName = (s) => {
		if (!s) {
			return
		}
		if (s.length <= 30) {
			return s
		}
		else {
			return s.slice(0, 30)
		}
	}

	return (
		<div className="top-nfts__tile">
			<div className="top-nfts__image-container">
				<div className="top-nfts__image-wrapper">
					<img className="top-nfts__image" alt="" src={nft.nftIcon} onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = Constants.nftFallbackImage;
					}}
					></img>
				</div>
				{/* <div className="top-nfts__image-container__view-details-button">
					<ViewDetailsButton onTap={null} />
				</div> */}
			</div>
			<div className="top-nfts__info">
				<div className="top-nfts__info__title">{getSlicedNftName(nft.name)}</div>
				<div className="top-nfts__info__amount">{nft.nftSales}</div>
			</div>
		</div>
	);
}

export default function TopNFTs({ data }) {
	const { t } = useTranslation();
	if (data.topNfts.length === 0) {
		return
	}
	return (
		<div className="section" key={'nft'}>
			<div className="section__top">
				<div className="section__top__title">{t('topNfts')}</div>
			</div>
			<div className="section__game-list">
				{data.topNfts.map((nft, i) => {
					return <NftTile nft={nft} key={i} />;
				})}
			</div>
		</div>
	);
}
