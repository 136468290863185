import React, { useEffect, useState } from 'react';
import ListSection from '../list_section/list_section';
import FeaturedSection from '../featured_section/featured_section';
import GenreSection from '../genre/genre';
import { qwebApi } from '../../qt/qwebApi';
import { fetchGameListing } from '../../utils/data_utils';
import { Constants, StorageKeys } from '../../constants';
import { ShimmerLoaderEffects } from '../shimmer_effect/shimmer_effect';
import { SomethingWentWrong } from '../something_went_wrong/something_went_wrong';
import { sendClickEvent } from '../../constants';
import { useTranslation } from 'react-i18next';

function LandingPage() {
	const { t } = useTranslation();
	const [showingTutorial, setTutorialState] = useState(false);
	useEffect(() => {
		setTimeout(function () {
			if (localStorage.getItem(StorageKeys.tutorialComplete) != 'complete') {
				setTutorialState(true);
			}
		}, 2000);

	}, [setTutorialState]);

	useEffect(() => {
		if (showingTutorial) {
			qwebApi({
				event: 'changeBannerBackgroundImage',
				data: {
					url: Constants.overlayImageUrl,
				},
			});
		}
		else {
			qwebApi({
				event: 'changeBannerBackgroundImage',
				data: {
					url: Constants.landingPageBackgroundUrl,
				},
			});
		}
	}, [showingTutorial])

	const getFeaturedSection = () => {
		if (gameListingData.EditorChoice) {
			return <FeaturedSection gameList={gameListingData.EditorChoice} gamesToTryList={gameListingData.GamesToTry} showingTutorial={showingTutorial} setTutorialState={setTutorialState} />;
		}
	};

	const getCoachMarkOverlay = () => {
		return <div className='overlay' onClick={() => {
			localStorage.setItem(StorageKeys.tutorialComplete, 'complete');
			setTutorialState(false);
		}} />;
	};

	const getMostPopularListSection = () => {
		if (gameListingData.MostPopular) {
			return (
				<ListSection
					props={{
						title: t('mostPopular'),
						gameList: gameListingData.MostPopular,
					}}
				/>
			);
		}
	};

	const getBrowseByGenreSection = () => {
		if (gameListingData.Genres) {
			return <GenreSection genreData={gameListingData.Genres} />
		}
	}

	const getHighestRatedListSection = () => {
		if (gameListingData.HighestRated) {
			return (
				<ListSection
					props={{
						title: t('highestRated'),
						gameList: gameListingData.HighestRated,
					}}
				/>
			);
		}
	};

	const getMostNFTSalesListSection = () => {
		if (gameListingData.MostNftSales) {
			return (
				<ListSection
					props={{
						title: t('mostNftSales'),
						gameList: gameListingData.MostNftSales,
					}}
				/>
			);
		}
	};

	const getExploreNewComersListSection = () => {
		if (gameListingData.ExploreNewComers) {
			return (
				<ListSection
					props={{
						title: t('exploreNewComers'),
						gameList: gameListingData.ExploreNewComers,
					}}
				/>
			);
		}
	};

	let [inAsyncCall, setInAsyncCall] = React.useState(true);
	let [error, setError] = React.useState(null);
	let [gameListingData, setGameListingData] = React.useState({});
	let fetchSetGameListingData = () => {
		fetchGameListing().then(setGameListingData).catch(setError)
	}


	useEffect(() => {
		fetchSetGameListingData();
		setInAsyncCall(false);
	}, [])

	useEffect(() => {
		const onVisibilityChange = () => {
			if (!document.hidden) {
				sendClickEvent({
					'banner_location': 'impression_web3_webapp',
					'event_type': 'App_JumpToDetailPage'
				});
				setTimeout(() => {
					qwebApi({
						event: 'changeBannerBackgroundImage',
						data: {
							url: Constants.landingPageBackgroundUrl,
						},
					});
				}, 100);
				window.location.reload();
			}
		}
		document.addEventListener('visibilitychange', onVisibilityChange)
		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		}
	}, [])

	if (inAsyncCall) {
		return ShimmerLoaderEffects.LandingPage();
	} else if (error) {
		return <SomethingWentWrong error={error} onRefreshClick={() => { fetchSetGameListingData() }} />
	}
	else if (gameListingData !== {}) {
		return (
			<div className="App" >
				{showingTutorial === true ? getCoachMarkOverlay() : null}
				<div id='listing-page'>
					{getFeaturedSection()}
					{getMostPopularListSection()}
					{getHighestRatedListSection()}
					{getMostNFTSalesListSection()}
					{getBrowseByGenreSection()}
					{getExploreNewComersListSection()}
				</div>
			</div>
		);
	}
}

export default LandingPage;
