class NetworkManager {
    static get({ absoluteApi, returnableObjectCallback, apiName, queryParams }) {
        const onSuccessfulResponse = (response, resolve) => {
            // console.log(`${apiName} Response:`, response);
            if (response && response.data && Array.isArray(response.data)) {
                const listOfObjects = response.data.map(returnableObjectCallback);
                resolve(listOfObjects);
            }
            else if (response && response.data) {
                const object = returnableObjectCallback(response.data);
                resolve(object);
            }
        }

        return new Promise((resolve, reject) => {
            fetch(absoluteApi, queryParams)
                .then((response) => {
                    return new Promise((innerResolve, innerReject) => {
                        if (response.status && response.status != 200 && response.status != 500) {
                            innerReject("{" + response.status + "}," + response.url + "," + JSON.stringify(response));
                        }
                        else if (response.status && response.status == 500) {
                            response.text().then((r) => {
                                innerReject("{" + response.status + "}," + response.url + "," + r);
                            }).catch((e) => { innerReject(e) });
                        }
                        else {
                            innerResolve(response.json());
                        }
                    })
                })
                .then(
                    (response) => {
                        return new Promise((_, innerReject) => {
                            try {
                                if (response.success) {
                                    onSuccessfulResponse(response, resolve);
                                }
                                else {
                                    let rejectString = "{" + response.status + "}," + absoluteApi + "," + JSON.stringify(response);
                                    innerReject(rejectString);
                                }
                            } catch (err) {
                                innerReject(err);
                            }
                        })
                    }
                )
                .catch((err) => { reject(err) });
        });
    }


}

export { NetworkManager }