import React from "react";
import { useTranslation } from 'react-i18next';
import { DialogIds } from "../../constants";
import { useCommonComponents } from "../../providers/common_components_provider";
import ReadMorePopup from "../game_detail_page/components/read_more_popup";

const ReadMore = ({ gameDetailData }) => {
    var { triggerPopup } = useCommonComponents();
    const { t } = useTranslation();
    const { description = "" } = gameDetailData || {};
    const text = description;
    let listOfPTags = []
    text.replace(/<p>(.*?)<\/p>/g, function (match, g1) {
        listOfPTags.push(g1)
    });
    let firstPTag = listOfPTags[0]
    firstPTag = "<p>" + firstPTag + "</p>"
    if (listOfPTags.length === 1) {
        return (<div dangerouslySetInnerHTML={{ __html: firstPTag }}></div>)
    }
    else {
        return (<div>
            <div dangerouslySetInnerHTML={{ __html: firstPTag }}></div>
            <span onClick={()=>triggerPopup({
                children: <ReadMorePopup gameDetailData={gameDetailData} />,
                id: DialogIds.readMore
            })} className="read-or-hide">
                {t('readMore')}
            </span>
        </div>)
    }
};

export default ReadMore;