import i18next from "i18next"
class Common {
    static abbreviateNumber(value) {
        if (!value) return null
        value = "" + value
        const prefixSign = value.includes('$') ? "$" : ""

        value = parseInt(value.replaceAll('$', ""))
        if (value < 1000) return value
        if (value >= 1000 && value < 1000000) {
            value = value / 1000
            value = value.toFixed(1)
            return prefixSign + value + "K"
        }
        if (value >= 1000000 && value < 1000000000) {
            value = value / 1000000
            value = value.toFixed(1)
            return prefixSign + value + "M"
        }
        if (value >= 1000000000 && value < 1000000000000) {
            value = value / 1000000000
            value = value.toFixed(1)
            return prefixSign + value + "B"
        }
    }

    static getLanguage() {
        return i18next.language;
    }

}

export { Common }