import AR from './web3_section_frontendjson_ar.json'
import DE from './web3_section_frontendjson_de.json'
import EN_US from './web3_section_frontendjson_en_US.json'
import EN from './web3_section_frontendjson_en.json'
import ES from './web3_section_frontendjson_es.json'
import FR from './web3_section_frontendjson_fr.json'
import ID from './web3_section_frontendjson_id.json'
import IT from './web3_section_frontendjson_it.json'
import JA from './web3_section_frontendjson_ja.json'
import KO from './web3_section_frontendjson_ko.json'
import NL from './web3_section_frontendjson_nl.json'
import PL from './web3_section_frontendjson_pl.json'
import PT from './web3_section_frontendjson_pt.json'
import RU from './web3_section_frontendjson_ru.json'
import TH from './web3_section_frontendjson_th.json'
import TR from './web3_section_frontendjson_tr.json'
import VI from './web3_section_frontendjson_vi.json'
import ZH_TW from './web3_section_frontendjson_zh_TW.json'

export const TranslationResources = {
    ar: { translation: AR },
    de: { translation: DE },
    en: { translation: EN_US },
    es: { translation: ES },
    fr: { translation: FR },
    id: { translation: ID },
    it: { translation: IT },
    ja: { translation: JA },
    ko: { translation: KO },
    nl: { translation: NL },
    pl: { translation: PL },
    pt: { translation: PT },
    ru: { translation: RU },
    th: { translation: TH },
    tr: { translation: TR },
    vi: { translation: VI },
    cht: { translation: ZH_TW },
}