import React from "react";
import BackButton from "../back_button/back_button";
import { sendClickEvent, gameTileSourceMapping } from "../../constants";
import { useTranslation } from 'react-i18next';

function RefreshButton({ onRefreshClick }) {
    const { t } = useTranslation();
    if (onRefreshClick) {
        return <div className="something-went-wrong__refresh-button" onClick={onRefreshClick}>
            <span className="button-title">{t('refresh')}</span>
        </div>
    }
}

function SomethingWentWrong({ error, onRefreshClick }) {
    const { t } = useTranslation();
    (() => {
        sendClickEvent({
            'banner_location': error,
            'event_type': 'App_JumpToDetailPage'
        });
    })();
    const onRefreshTap = () => {
        sendClickEvent({
            'banner_location': gameTileSourceMapping['refresh'],
            'event_type': 'App_JumpToDetailPage'
        });
        onRefreshClick();
    }
    return <div className="something-went-wrong-wrapper">
        <BackButton hideText={true} />
        <div className="something-went-wrong">
            <div className="something-went-wrong__error-board">
                <span className="something-went-wrong__error-sign">!</span>
            </div>
            <div className="something-went-wrong__title">{t('somethingWentWrong')}</div>
            <div className="something-went-wrong__try-again">{t('tryAgainLater')}</div>
            <RefreshButton onRefreshClick={onRefreshTap} />
        </div>
    </div>
}
export { SomethingWentWrong }