import Cross from '../../assets/ic_close.png'
import './popup.scss'

const Popup = ({
  children = null,
  className = '',
  hidePopup,
  popupId,
  hideCloseIcon = false,
  closeIcon
}) => {

  return (
    <div className={`popup-overlay ${className}`}>
        <div className={'popup'}>
          {hideCloseIcon ? '' :
            <div className="close-icon" onClick={() => {
              hidePopup(popupId)
            }}>
              {closeIcon || <img src={Cross} alt='close' />}
            </div>}
          {children}
        </div>
    </div>
  )
}

export default Popup
